"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-74d39c42"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "card" };
const _hoisted_2 = { class: "card-title" };
const _hoisted_3 = { class: "card-title-extra" };
const _hoisted_4 = { class: "card-content" };
exports.default = (0, vue_1.defineComponent)({
    props: {
        cardTitle: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(__props.cardTitle), 1),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "title-extra")
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.renderSlot)(_ctx.$slots, "card-content")
                ])
            ]));
        };
    }
});
